/* eslint-disable @typescript-eslint/naming-convention */
import { Auth } from '@aws-amplify/auth';
import { environment } from './environments/environment';

console.log('Use environment for', environment.stage);

Auth.configure({
  region: environment.region,
  userPoolId: environment.userPoolId,
  userPoolWebClientId: environment.clientId,
  mandatorySignIn: true,

  oauth: {
    domain:
      environment.cognitoDomainPrefix +
      '.auth.' +
      environment.region +
      '.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: environment.cognitoRedirectUri,
    redirectSignOut: environment.cognitoLogoutUri,
    responseType: 'code',
  },
});
