<p class="mat-typography">
    <mat-toolbar color="primary">
        <span>Catena-X</span>
        <span class="flex-auto"></span>
        <mat-nav-list class="list-horizontal">
            <mat-list-item>
                <a [matMenuTriggerFor]="shellManagement">Shell Management</a>
                <mat-menu #shellManagement="matMenu">
                    <button mat-menu-item [routerLink]="['/secure/shells/part-instances']">Part Instances</button>
                    <button mat-menu-item [routerLink]="['/secure/shells/catalog-parts']">Catalog Parts</button>
                </mat-menu>
                <!-- <a [routerLink]="['/secure/shells']">Shell Management</a> -->
            </mat-list-item>

            <mat-list-item>
                <a [routerLink]="['/secure/asset-transfer/post-requests']">Unique ID Push</a>
            </mat-list-item>

            <mat-list-item>
                <a [matMenuTriggerFor]="adminResources">Admin</a>
                <mat-menu #adminResources="matMenu">
                    <button mat-menu-item [routerLink]="['/secure/edc-resources/edc-list']">EDR Management</button>
                    <button mat-menu-item>Cluster Management</button>
                </mat-menu>
            </mat-list-item>
            <!--
            <mat-list-item>
                <a>Future Master Data</a>
            </mat-list-item>
            -->
        </mat-nav-list>
        <span class="flex-auto"></span>
        <button mat-icon-button (click)="logout()">
            <mat-icon>logout</mat-icon>
        </button>
    </mat-toolbar>
</p>