const hasLocalhost = (hostname: string) =>
    Boolean(
      hostname.match(/localhost/) ||
        hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
    );
  const isLocalhost = hasLocalhost(window.location.hostname);
  export const environment = {
    stage: 'dev2',
    userPoolId: 'eu-west-1_ZifETEFAt',
    region: 'eu-west-1',
    clientId: '77m510sv0ss7oug1tb9g5rhoks',
    cognitoDomainPrefix: 'cxp-admins-dev',
    cognitoRedirectUri: isLocalhost
      ? 'http://localhost:4200/secure'
      : 'https://catenax-dev2.draexlmaier.solutions/secure',
    cognitoLogoutUri: isLocalhost
      ? 'http://localhost:4200/login'
      : 'https://catenax-dev2.draexlmaier.solutions/login',
    assetManagementApiUrl: 'https://u1soy0359j.execute-api.eu-west-1.amazonaws.com/dev2/',
    assetTransferApiUrl: 'https://3xfyb9pbuc.execute-api.eu-west-1.amazonaws.com/dev2/',
    basicAdmnistrationApiUrl: 'https://0w47qs5ioe.execute-api.eu-west-1.amazonaws.com/dev2/admin/'
  };
  