import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Observable, from, map, mergeMap } from 'rxjs';

const getToken = () => {
  return from(Auth.currentSession()).pipe(
    map((session: CognitoUserSession) => session.getIdToken().getJwtToken())
  );
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const codec = new HttpUrlEncodingCodec();

    if (req.url.indexOf('?') !== -1) {
      const queryParams = req.url.split('?')[1];
      if (
        queryParams.includes('AWSAccessKeyId') &&
        queryParams.includes('Signature')
      ) {
        return next.handle(req);
      }
    }

    // Don't add authorization for local URLs
    if (!req.url.startsWith('http')) {
      return next.handle(req);
    }

    return getToken().pipe(
      mergeMap((token) => {
        const idToken = token;
        if (idToken) {
          const encodedUrl = codec.encodeValue(req.url);
          const cloned = req.clone({
            url: encodedUrl,
            headers: req.headers
              .set('Authorization', idToken)
              // .set('Access-Control-Allow-Origin', '*')
              // .set('Access-Control-Allow-Headers', '*')
              // .set('Access-Control-Allow-Methods', '*')
              // .set('Content-Type', "application/json"),

          });
          return next.handle(cloned);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
