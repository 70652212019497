import { Route } from '@angular/router';
import { SecureComponent } from './secure.component';
import { authGuard } from 'src/_guards/auth.guard';

export const secureRoutes: Route[] = [
  {
    path: 'secure',
    component: SecureComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'shells/part-instances',
        pathMatch: 'full',
      },
      {
        path: 'shells/catalog-parts',
        loadComponent: () =>
          import('./shells/shell-list/catalog-parts/catalog-parts.component').then(
            (m) => m.CatalogPartsComponent
          ),
      },
      {
        path: 'shells/part-instances',
        loadComponent: () =>
          import('./shells/shell-list/part-instances/part-instances.component').then(
            (m) => m.PartInstancesComponent
          ),
      },
      // {
      //   path: 'shells',
      //   loadComponent: () =>
      //     import('./shells/shell-list/shell-list.component').then(
      //       (m) => m.ShellListComponent
      //     ),
      // },
      {
        path: 'shell-details/:globalId',
        loadComponent: () =>
          import('./shells/shell-detail/shell-detail.component').then(
            (m) => m.ShellDetailComponent
          ),
      },
      {
        path: 'asset-transfer/post-requests',
        loadComponent: () =>
          import('./asset-transfer/post-requests/post-requests.component').then((m) => m.PostRequestsComponent),
      },
      {
        path: '**',
        redirectTo: 'shells/catalog-parts',
      },
    ],
  },

  // {
  //   path: '**',
  //   redirectTo: 'secure/dashboard',
  // },
];
