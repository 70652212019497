import { Routes } from '@angular/router';
import { secureRoutes } from './secure/secure.routes';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'secure',
  },
  ...secureRoutes,
  {
    path: 'login',
    loadComponent: () =>
      import('./authentication/authentication.component').then(
        (comp) => comp.AuthenticationComponent,
      ),
  },
];
