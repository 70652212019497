import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { catchError, from, map, of } from 'rxjs';
export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  return from(Auth.currentAuthenticatedUser()).pipe(
    map((user) => {
      console.log('User:', user);
      return true;
    }),
    catchError((error) => {
      console.log('Auth Guard error:', error);
      return of(router.createUrlTree(['/login']));
    }),
  );
};
