import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { AuthInterceptor } from 'src/_interceptors/auth.interceptor';
import { FormlyModule } from '@ngx-formly/core';
import { AutocompleteTypeComponent } from 'src/_components/autocomplete-type.component';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    importProvidersFrom([
      FormlyModule.forRoot({
        types: [{
          name: 'autocomplete',
          component: AutocompleteTypeComponent,
          wrappers: ['form-field'],
        }],
        validationMessages: [
          { name: 'required', message: 'This field is required' },
        ],
          
        }),
  ]),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
   
  ],
};
